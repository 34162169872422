code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

/**
* This injects Tailwind's base styles and any base styles registered by
* plugins.
*/
@tailwind base;

/**
* This injects Tailwind's component classes and any component classes
* registered by plugins.
*/
@tailwind components;

/**
* This injects Tailwind's utility classes and any utility classes registered
* by plugins.
*/
@tailwind utilities;