.node-label {
  font-size: 12px;
  padding: 1px 4px;
  border-radius: 4px;
  user-select: none;
  text-align: center;
  color: white;
  margin-top: 1rem;
}

.axis {
  color: #e1ebf7
}