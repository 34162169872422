.spinner-container {
  z-index: 99;
  padding: 10px;
  border-radius: 0.5rem;
}


#inner {
  animation: rotate linear;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  transform-origin: center;
  transform-box: border-box;
}

#outer {
  animation: rotate-inverse linear;

  animation-duration: 4s;
  animation-iteration-count: infinite;
  transform-origin: center;
  transform-box: border-box;
}

@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes rotate-inverse {
  0% {
    transform: rotateZ(360deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
